<template>
  <div id="stop">
    <div class="page-title px-4 mb-4">
      <h2>
        <span class="me-2">{{ stop.stop_code }} - {{ stop.stop_name }}</span>
      </h2>
      <save-button :save-action="saveStopConfig"></save-button>
    </div>
    <v-divider class="mt-4 mb-10"></v-divider>
    <v-row v-if="!loading">
      <v-col cols="12" md="5" lg="4">
        <stop-info-panel />
      </v-col>
      <v-col cols="12" md="7" lg="8">
        <stop-overview ref="stopOverview" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiCircleSlice8 } from "@mdi/js"
import { getCurrentInstance } from "@vue/composition-api"
import { mapState } from "vuex"
import StopInfoPanel from "./components/StopInfoPanel.vue"
import StopOverview from "./components/StopOverview.vue"
import SaveButton from "../components/SaveButton.vue"
import "mapbox-gl/dist/mapbox-gl.css"
import store from "@/store"

export default {
  name: "StopConfiguration",
  metaInfo() {
    return {
      title: `Edit Stop`,
    }
  },
  components: {
    StopInfoPanel,
    StopOverview,
    SaveButton,
  },
  computed: {
    ...mapState({
      routes: state => state.gtfs.routes,
      stops: state => state.gtfs.stops,
      stop: state => state.selected_stop,
    }),
  },
  created() {
    store.dispatch("getStop", this.stopId).then(() => {
      this.loading = false
    })
  },
  methods: {
    saveStopConfig() {
      this.$refs.stopOverview.saveStopConfig()
    },
  },
  setup() {
    const curr = getCurrentInstance()
    const stopId = curr.proxy.$route.params.id

    if (!stopId) {
      curr.proxy.$router.push({
        name: "next-stop-stops",
      })
    }

    return {
      stopId,
      loading: true,
      icons: {
        mdiCircleSlice8,
      },
    }
  },
}
</script>
