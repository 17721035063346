<template>
  <v-avatar :class="avatarClass" :color="'#' + route.route_color" :size="size">
    <component :is="innerElement" class="white--text" :class="textClass">
      {{ route.route_short_name }}
    </component>
  </v-avatar>
</template>

<script>
export default {
  name: "RouteBadge",
  props: {
    size: {
      type: Number,
      required: true,
      default: 30,
    },
    avatarClass: {
      type: String,
      default: "",
    },
    textClass: {
      type: String,
      default: "",
    },
    route: {
      type: Object,
      required: true,
    },
    innerElement: {
      type: String,
      default: "span",
    },
  },
}
</script>
