<template>
  <div id="stop-overview">
    <v-tabs v-model="currentTab" show-arrows>
      <v-tab v-for="tab in tabs" :key="tab.icon">
        <v-icon class="me-3">
          {{ tab.icon }}
        </v-icon>
        <span>
          {{ tab.title }}
        </span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="currentTab">
      <v-tab-item>
        <v-card class="mb-4">
          <v-card-text>
            <v-row>
              <v-col>
                <h2 class="text-left text-xl font-weight-semibold label">Stop name</h2>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="10">
                <v-alert border="left" color="primary" text dense>
                  <p class="text--primary font-weight-medium font-italic">
                    {{ stop.stop_name }}
                  </p>
                </v-alert>
              </v-col>
              <v-col cols="1" class="d-flex justify-center align-center">
                <v-btn class="" fab dark small color="primary" @click="TTSpreview(stop.stop_name)">
                  <v-icon x-large>
                    {{ icons.mdiPlayCircle }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="10">
                <v-textarea
                  v-model="stop_settings.stop_tts"
                  label="Custom stop name TTS"
                  rows="1"
                  outlined
                  hide-details
                  :rules="[v => !v || (v && v.length <= 64) || 'Stop name must be 64 characters or less']"
                >
                </v-textarea>
                <tooltip-for-text-to-speech-field></tooltip-for-text-to-speech-field>
              </v-col>
              <v-col cols="1" class="d-flex justify-center align-center">
                <v-btn class="mb-8" fab dark small color="primary" @click="TTSpreview(stop_settings.stop_tts)">
                  <v-icon x-large>
                    {{ icons.mdiPlayCircle }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <h2 class="text-left text-xl font-weight-semibold label">Additional message</h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <v-textarea
                  v-model="stop_settings.additional_text"
                  label="Additional message text"
                  rows="6"
                  outlined
                  hide-details="auto"
                  :rules="[
                    v => !v || (v && v.length <= 256) || 'Additional text message must be 256 characters or less',
                  ]"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <v-textarea
                  v-model="stop_settings.additional_tts"
                  label="Additional message TTS"
                  rows="6"
                  outlined
                  hide-details="auto"
                  :hint="
                    stop_settings.additional_tts && !stop_settings.additional_text
                      ? 'Warning: The audio won\'t play unless the \'Additional message text\' field is also set'
                      : null
                  "
                  persistent-hint
                  :rules="[
                    v => !v || (v && v.length <= 256) || 'Additional TTS message must be 256 characters or less',
                  ]"
                >
                </v-textarea>
                <tooltip-for-text-to-speech-field></tooltip-for-text-to-speech-field>
              </v-col>
              <v-col cols="1" class="d-flex justify-center align-center">
                <v-btn class="mb-8" fab dark small color="primary" @click="TTSpreview(stop_settings.additional_tts)">
                  <v-icon x-large>
                    {{ icons.mdiPlayCircle }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card class="mb-4 pb-3">
          <v-card-text>
            <v-row>
              <v-col>
                <v-switch
                  v-model="stop_settings.transfers_enabled"
                  label="Enable transfers annoucement"
                  class="v-input--reverse"
                  hide-details
                >
                </v-switch>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <div v-if="stop_settings.transfers_enabled">
          <v-card class="mb-4">
            <v-card-text>
              <v-row>
                <v-col>
                  <h2 class="text-left text-xl font-weight-semibold label">Custom transfer announcement</h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10">
                  <v-textarea
                    v-model="stop_settings.custom_transfer_tts"
                    label="Custom transfer message TTS"
                    rows="2"
                    outlined
                    hide-details
                    :rules="[
                      v => !v || (v && v.length <= 256) || 'Custom transfer message must be 256 characters or less',
                    ]"
                  >
                  </v-textarea>
                  <tooltip-for-text-to-speech-field></tooltip-for-text-to-speech-field>
                </v-col>
                <v-col cols="1" class="d-flex justify-center align-center">
                  <v-btn
                    class="mb-8"
                    fab
                    dark
                    small
                    color="primary"
                    @click="TTSpreview(stop_settings.custom_transfer_tts)"
                  >
                    <v-icon x-large>
                      {{ icons.mdiPlayCircle }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card v-if="stop_settings.transfers_enabled">
            <v-card-text>
              <v-row>
                <v-col>
                  <h2 class="text-left text-xl font-weight-semibold label">Route transfers</h2>
                </v-col>
              </v-row>
              <v-row class="align-right">
                <v-col class="filter d-flex align-center" cols="9">
                  <v-autocomplete
                    v-model="selectedRoutes"
                    label=""
                    :items="addToTransferCandidates"
                    item-value="route_id"
                    :item-text="item => item.route_short_name + ' - ' + item.route_long_name"
                    return-object
                    outlined
                    hide-details
                    placeholder="Add one or multiple routes to transfer list"
                    multiple
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col class="d-flex justify-center align-center" cols="3">
                  <v-btn small class="no-text-transform" color="primary" @click="addToTransferList">
                    Add to transfer list
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text v-if="stop_settings.transfers_enabled">
              <v-data-table
                :headers="transfersTableHeaders"
                :items="stop_settings.transfers"
                :single-expand="false"
                item-key="route_id"
                hide-default-footer
                hide-default-header
                disable-pagination
              >
                <template #[`item.route_id`]="{ item }">
                  <h2 class="text-sm font-weight-bold mb-1 mt-1">
                    <route-badge
                      :route="routes.find(r => r.route_id === parseInt(item.route_id, 10))"
                      :size="38"
                      avatar-class="vehicle-avatar"
                      text-class="text-1xl"
                    ></route-badge>
                  </h2>
                </template>

                <template #[`item.route_type`]="{ item }">
                  <div :set="(route = routes.find(r => r.route_id === parseInt(item.route_id, 10)))">
                    <span
                      class="text-capitalize font-weight-semibold text--primary"
                      :set="(routesArray[item.route_id] = getRouteTypePresentation(route.route_type))"
                    >
                      <v-tooltip bottom class="text--primary">
                        <template #activator="{ on, attrs }">
                          <v-icon size="20" v-bind="attrs" v-on="on">
                            {{ routesArray[item.route_id].icon }}
                          </v-icon>
                        </template>
                        <span>{{ routesArray[item.route_id].text }}</span>
                      </v-tooltip>
                    </span>
                  </div>
                </template>

                <template #[`item.route_long_name`]="{ item }">
                  <span
                    class="text-capitalize font-weight-semibold text--primary"
                    :set="(route = routes.find(r => r.route_id === parseInt(item.route_id, 10)))"
                    >{{ route.route_long_name }}</span
                  >
                </template>

                <template #[`item.remove`]="{ item }">
                  <div
                    class="d-flex align-center justify-right"
                    :set="(route = routes.find(r => r.route_id === parseInt(item.route_id, 10)))"
                  >
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          icon
                          small
                          v-bind="attrs"
                          color="primary"
                          v-on="on"
                          @click="removeFromTransferList(item)"
                        >
                          <v-icon size="18">
                            {{ icons.mdiLinkOff }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Remove route {{ route.rount_id }} from transfer routes</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </div>
        <div v-else>
          <v-row class="mb-4">
            <v-col class="d-flex justify-center align-center" cols="12">
              <h2 class="text-left text-xl font-weight-semibold label">Transfer disabled</h2>
            </v-col>
          </v-row>
        </div>
      </v-tab-item>

      <v-tab-item v-if="canNotes">
        <v-card>
          <!-- Notes Filter fields start -->
          <v-row class="pt-4 pb-0 ma-0">
            <v-col cols="12" sm="4">
              <v-select
                v-model="sqPriority"
                :items="priorityItems"
                item-text="text"
                item-value="value"
                label="Priority"
                placeholder="Priority"
                outlined
                hide-details
                clearable
                dense
                class="user-search me-3"
                @change="val => addToSearchHistory(val, 'sqPriority')"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="sqClaimant"
                label="Claimant"
                outlined
                hide-details
                clearable
                persistent-clear
                dense
                class="user-search me-3"
                @change="val => addToSearchHistory(val, 'sqClaimant')"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-menu
                v-model="menu"
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="sqCreated"
                    label="Date"
                    readonly
                    v-bind="attrs"
                    outlined
                    hide-details
                    dense
                    clearable
                    class="user-search me-3"
                    v-on="on"
                    @change="val => addToSearchHistory(val, 'sqCreated')"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="sqCreated" no-title @input="menu = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <!-- Notes Filter fields end -->

          <!-- Notes Table start -->
          <v-data-table
            :headers="noteHeaders"
            :items="filteredNotes"
            :sort-by.sync="noteSortBy"
            :sort-desc.sync="noteSortDesc"
            :custom-sort="customSort"
            class="font-weight-semibold text--primary"
            :must-sort="true"
          >
            <template v-slot:item.note_id="{ item }">
              <router-link
                :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="font-weight-semibold text--primary">{{ item.note_id }}</span>
                  </div>
                </div>
              </router-link>
            </template>

            <template v-slot:item.title="{ item }">
              <router-link
                :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="font-weight-semibold text--primary">{{ item.title }}</span>
                  </div>
                </div>
              </router-link>
            </template>

            <template v-slot:item.priority="{ item }">
              <router-link
                :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="font-weight-semibold text--primary">{{ item.priority }}</span>
                  </div>
                </div>
              </router-link>
            </template>

            <template v-slot:item.status="{ item }">
              <router-link
                :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="font-weight-semibold text--primary">{{ item.status }}</span>
                  </div>
                </div>
              </router-link>
            </template>

            <template v-slot:item.claimant="{ item }">
              <router-link
                :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="font-weight-semibold text--primary">{{ item.claimant }}</span>
                  </div>
                </div>
              </router-link>
            </template>

            <template v-slot:item.convertCreatedDate="{ item }">
              <router-link
                :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="font-weight-semibold text--primary" v-html="convertCreatedDate(item)"></span>
                  </div>
                </div>
              </router-link>
            </template>
          </v-data-table>
          <!-- Notes Table end -->

          <!-- Add Note Button, Modal and Form start -->
          <v-row class="pt-8 pl-5 pb-6 ma-0">
            <v-btn color="primary" elevation="0" @click="openDialog">Add Note</v-btn>
          </v-row>

          <v-dialog v-model="dialog" max-width="600">
            <v-card>
              <v-card-title class="d-flex">
                <span class="headline flex-grow-1">Add Note</span>
                <v-btn color="secondary" outlined elevation="0" @click="closeDialog">Close</v-btn>
              </v-card-title>
              <v-card-text>
                <v-form lazy-validation @submit.prevent="() => {}">
                  <v-text-field v-model="formData.title" label="Title *"></v-text-field>
                  <v-textarea v-model="formData.comments" label="Comments *"></v-textarea>
                  <v-select v-model="formData.selectedPriority" :items="priorityOptions" label="Select Priority *">
                  </v-select>
                  <v-select v-model="formData.selectedStatus" :items="statusOptions" label="Select Status *"></v-select>
                  <v-text-field v-model="formData.claimant" label="Claimant *"></v-text-field>
                  <v-text-field v-model="formData.assigned" label="Assigned To (optional)"></v-text-field>
                  <v-text-field v-model="formData.location" label="Location (optional)"></v-text-field>
                  <v-btn class="mt-7" color="primary" :disabled="note_cannotSave" @click="addNote()"> Add </v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
          <!-- Add Note Button, Modal and Form end -->
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import {
  mdiTransitDetour,
  mdiMessagePlus,
  mdiOpenInNew,
  mdiLinkOff,
  mdiTableColumnPlusBefore,
  mdiPlayCircle,
  mdiCommentTextOutline,
  mdiTextBoxMultipleOutline,
} from "@mdi/js"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import RouteBadge from "@/components/route/Badge.vue"
import TooltipForTextToSpeechField from "../../components/TooltipForTextToSpeechField.vue"
import store from "@/store"
import globalHelpers from "@/mixins/globalHelpers"
import urlHelpers from "@/mixins/urlHelpers"

dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: "StopOverview",
  components: {
    RouteBadge,
    TooltipForTextToSpeechField,
  },
  mixins: [globalHelpers, urlHelpers],
  data() {
    return {
      currentTab: "",
      selectedRoutes: [],
      stop_settings: {},
      routeToCustom: null,
      transfersTableHeaders: [
        { text: "ID", value: "route_id", sortable: false },
        { text: "Type", value: "route_type", sortable: false },
        { text: "Description", value: "route_long_name", sortable: false },
        { text: "", value: "route_url", sortable: false },
        {
          text: "",
          value: "remove",
          sortable: false,
          align: "start",
        },
      ],
      routesArray: [],
      icons: {
        mdiOpenInNew,
        mdiLinkOff,
        mdiTableColumnPlusBefore,
        mdiPlayCircle,
        mdiCommentTextOutline,
      },
      sqCreated: "",
      sqClaimant: "",
      sqPriority: null,
      noteSortBy: "note_id",
      menu: false,
      // Notes Table Headers
      noteHeaders: [
        { text: "Note ID", value: "note_id" },
        { text: "Title", value: "title" },
        { text: "Priority", value: "priority" },
        { text: "Status", value: "status" },
        { text: "Claimant", value: "claimant" },
        { text: "Created", value: "convertCreatedDate" },
      ],
      priorityItems: [
        {
          text: "Low",
          value: "Low",
        },
        {
          text: "Medium",
          value: "Medium",
        },
        {
          text: "High",
          value: "High",
        },
        {
          text: "Urgent",
          value: "Urgent",
        },
      ],
      is_loading: true,
      formData: {
        selectedType: "Stop",
        selectedForeignId: null,
        title: "",
        comments: "",
        selectedPriority: null,
        selectedStatus: null,
        claimant: "",
        assigned: "",
        location: "",
      },
      dialog: false,
      noteSortDesc: false, // true for descending order, false for ascending order
    }
  },
  computed: {
    ...mapState({
      routes: state => state.gtfs.routes,
      stop: state => state.selected_stop,
      notes: state => state.gtfs.notes,
    }),

    tabs() {
      const tabs = [
        { title: "Configuration", icon: mdiMessagePlus },
        { title: "Transfers", icon: mdiTransitDetour },
      ]

      if (this.canNotes) {
        tabs.push({ title: "Notes", icon: mdiTextBoxMultipleOutline })
      }

      return tabs
    },

    addToTransferCandidates() {
      const existingTransfers = this.stop_settings.transfers.map(t => parseInt(t.route_id, 10))
      const candidates = this.routes.filter(route => !existingTransfers.includes(route.route_id))

      const heading1 = [{ disabled: true, divider: true, header: "Routes Going Through The Stop" }]
      const group1 = candidates.filter(route => this.stop.routes.includes(route.route_id))
      const heading2 = [{ disabled: true, divider: true, header: "Other Routes" }]
      const group2 = candidates.filter(route => !this.stop.routes.includes(route.route_id))

      return [...heading1, ...group1, ...heading2, ...group2]
    },

    canNotes() {
      return this.$auth.hasScope("notes")
    },

    // Notes Filtering
    filteredNotes() {
      return this.notes.filter(note => {
        let include = true

        if (include && this.sqCreated !== "" && this.sqCreated !== null) {
          // eslint-disable-next-line no-undef
          const date = dayjs(this.formatDate(note.created, "en-NZ"), "D/MM/YYYY, h:mm:ss a")
          include = date.format("YYYY-MM-DD") === this.sqCreated
        }

        if (include && this.sqClaimant !== "" && this.sqClaimant !== null) {
          include = note.claimant.toLowerCase().includes(this.sqClaimant.toLowerCase())
        }

        if (include && this.sqPriority !== "" && this.sqPriority !== null) {
          include = note.priority === this.sqPriority
        }

        return include
      })
    },

    convertCreatedDate() {
      return function (item) {
        return item.created ? this.formatDate(item.created, "en-NZ") : "Unknown"
      }
    },

    priorityOptions() {
      return ["Low", "Medium", "High", "Urgent"]
    },

    statusOptions() {
      return ["Open", "Pending", "On Hold", "Closed"]
    },

    note_cannotSave() {
      return (
        !this.formData.title ||
        !this.formData.comments ||
        !this.formData.selectedPriority ||
        !this.formData.selectedStatus ||
        !this.formData.claimant
      )
    },
  },

  created() {
    this.stop_settings = JSON.parse(JSON.stringify(this.stop.config))

    this.formData.selectedForeignId = {
      title: `${this.stop.stop_code} - ${this.stop.stop_name}`,
      value: this.stop.stop_id,
    }

    this.is_loading = true
    store
      .dispatch("getNotes", {
        type: "Stop",
        foreign_id: this.stop.stop_id,
      })
      .then(() => {
        this.is_loading = false
      })
  },

  methods: {
    ...mapActions(["TTSpreview"]),

    getRouteTypePresentation(routeType) {
      return this.routeTypePresentation(routeType)
    },

    addToTransferList() {
      this.selectedRoutes.forEach(route => {
        this.stop_settings.transfers.push({ route_id: route.route_id, custom_route_tts: null, is_enabled: false })
      })
      this.selectedRoutes = []

      store.commit("SET_SNACKBAR", {
        text: "The selected routes have been added to transfer route list",
      })
    },

    removeFromTransferList(route) {
      this.stop_settings.transfers.splice(
        this.stop_settings.transfers.findIndex(
          transfer => parseInt(transfer.route_id, 10) === parseInt(route.route_id, 10),
        ),
        1,
      )
    },

    saveStopConfig() {
      store.dispatch("saveStopConfig", this.stop_settings)
    },

    /**
     * Overrides default sorting by table headers.
     */
    customSort(items, sortBy, sortDesc) {
      const sortByField = sortBy[0] ?? ""
      const sortDescending = sortDesc[0] ?? false

      items.sort((a, b) => {
        let sortA = null
        let sortB = null

        // Handle sorting based on clicked header column
        switch (sortByField) {
          case "convertCreatedDate": {
            // We don't want to use convertCreatedDate as it can return "Unknown"
            sortA = new Date(a?.created)
            sortB = new Date(b?.created)

            break
          }
          case "priority": {
            // Order by priority not alphabetically
            const priorityOrder = {
              urgent: 1,
              high: 2,
              medium: 3,
              low: 4,
            }

            sortA = priorityOrder[a.priority?.toLowerCase()] ?? 5
            sortB = priorityOrder[b.priority?.toLowerCase()] ?? 5

            break
          }
          default: {
            // Default sorting
            sortA = a[sortBy]
            sortB = b[sortBy]

            break
          }
        }

        if (sortA < sortB) {
          return -1
        }

        if (sortA > sortB) {
          return 1
        }

        return 0
      })

      // Reverse order if header clicked again
      if (sortDescending) {
        items.reverse()
      }

      return items
    },

    openDialog() {
      this.dialog = true
    },

    closeDialog() {
      this.dialog = false
    },

    async addNote() {
      try {
        const { selectedForeignId, title, comments, selectedPriority, selectedStatus, claimant } = this.formData

        // if fields filled out
        if (
          title.trim() !== "" &&
          comments.trim() !== "" &&
          selectedPriority &&
          selectedStatus !== null &&
          claimant.trim() !== ""
        ) {
          await this.$store.dispatch("addNote", { formData: this.formData })

          store.commit("SET_SNACKBAR", {
            text: `The note ${title} on ${selectedForeignId.title} has been added`,
            color: "success",
          })

          // Reset the form fields after successful submission
          this.formData = {
            selectedType: "Stop",
            selectedForeignId: {
              title: `${this.stop.stop_code} - ${this.stop.stop_name}`,
              value: this.stop.stop_id,
            },
            title: "",
            comments: "",
            selectedPriority: null,
            selectedStatus: null,
            claimant: "",
            assigned: "",
            location: "",
          }

          this.dialog = false
        }
      } catch (error) {
        console.error("error:", error)
        store.commit("SET_SNACKBAR", {
          text: `The note ${this.formData.title} on ${this.formData.selectedForeignId.title} could not be added`,
          color: "error",
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.no-text-transform {
  text-transform: unset !important;
}
.v-alert {
  margin-bottom: 0 !important;
}
</style>
